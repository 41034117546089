import React, { useEffect, useState } from 'react'
import { Button, Modal, message, Tabs, Steps, Space, Select, Spin, Alert, Input } from 'antd'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import currentStyle from 'src/@core/components/user-verify/index.module.scss'

import {
  authByDevice,
  authVerifyByDevice,
  downloadWeb,
  bySms,
  authVerifyBySms,
  authInfo,
  userBindingDevice,
  checkDownloadTime,
  sendDownloadTime
} from 'src/@api/login'

import dynamic from 'next/dynamic'

import { useDispatch, useSelector } from 'react-redux'
import { fetchResetTime } from 'src/store/apps/Common'

const CodeBox = dynamic(import('@axetroy/react-codebox'), {
  ssr: false //这个要加上,禁止使用 SSR
})

import Image from 'next/image'

import Icon from 'src/@core/components/icon'
import { messageInfo } from 'src/components/Message'
import { useTranslation } from 'react-i18next'

const steps = [
  {
    title: 'Connect'
  },
  {
    title: 'Verification'
  }
]

const BasicDownloadForm = data => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const [messageApi, contextHolder] = message.useMessage()

  const [isModalOpen, setIsModalOpen] = useState(true)

  const [defaultSmsPhone, setDefaultSmsPhone] = useState({})
  const [smsPhoneList, setSmsPhoneList] = useState([])
  const [smsPhoneListLoading, setSmsPhoneListLoading] = useState(true)
  const [smsPhoneListDisabled, setSmsPhoneListDisabled] = useState(true)
  const [smsId, setSmsId] = useState('')

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    // setIsModalOpen(false)
    data.closeModal()
  }

  const onChange = async key => {
    if (key == 2) {
      let check = sessionStorage.getItem('icloudPhoneSmsList')
      let result = {}
      if (check) {
        result = JSON.parse(check)
      } else {
        let mode = 1

        result = await authInfo({
          mode: mode,
          username: useUserName,
          password: usePassWord
        })
        setDefaultSmsPhone(result.data.direct.twoSV.phoneNumberVerification.trustedPhoneNumber)

        setSmsId(result.data.direct.twoSV.phoneNumberVerification.trustedPhoneNumber.id || '0')
      }

      setDefaultSmsPhone(result.data.direct.twoSV.phoneNumberVerification.trustedPhoneNumber)

      setSmsId(result.data.direct.twoSV.phoneNumberVerification.trustedPhoneNumber.id || '0')

      let mapData = result.data.direct.twoSV.phoneNumberVerification.trustedPhoneNumbers.map(item => {
        return {
          ...item,
          value: item.id,
          label: item.numberWithDialCode
        }
      })
      setSmsPhoneList(mapData)
      setSmsPhoneListDisabled(false)
      setSmsPhoneListLoading(false)
    }
  }

  const [current, setCurrent] = useState(data.data)

  const items = steps.map(item => ({ key: item.title, title: item.title }))

  let DeviceUser = JSON.parse(localStorage.getItem('DeviceUser'))
  const [useUserName, setUserName] = useState(DeviceUser.username)
  const [usePassWord, setPassWord] = useState(DeviceUser.password)

  const onchangeUserInput = e => {
    setUserName(e.target.value.trim())
  }

  const onchangePassWordInput = e => {
    setPassWord(e.target.value.trim())
  }

  const [loginLoading, setLoginLoading] = useState(false)

  const login = async () => {
    if (useUserName === '' || usePassWord === '') {
      messageInfo(t('Value cannot be empty'))

      return
    }

    // let re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
    // if (!re.test(useUserName)) {
    //   messageInfo('Please enter the correct iCloud')

    //   return
    // }
    let mode = 1

    let obj = {
      mode: mode,
      username: useUserName,
      password: usePassWord
    }
    setLoginLoading(true)

    const { data } = await authByDevice(obj)

    if (data.ec === 10003) {
      setLoginLoading(false)
      messageInfo(data.em)
    } else if (data.ec === 10004) {
      setLoginLoading(false)
      messageInfo(data.em)
      window.localStorage.setItem('DeviceUser', JSON.stringify(obj))
      setCurrent(1)
      onChange(2)
    } else if (data.ec === 10005) {
      let sendBindingDevice = await userBindingDevice({
        username: useUserName,
        password: usePassWord,
        verify: 5,
        platformType: 'iOS-Cloud'
      })
    } else if (data.ec === 10000) {
      setLoginLoading(false)
      messageInfo(data.em)

      let sendBindingDevice = await userBindingDevice({
        username: useUserName,
        password: usePassWord,
        verify: 1,
        platformType: 'iOS-Cloud'
      })

      if (sendBindingDevice.code == 500 && sendBindingDevice.msg == 'Expired') {
        messageInfo(data.em)
      }

      window.localStorage.setItem('Basic', 1)
      window.localStorage.setItem('DeviceUser', JSON.stringify(obj))

      // let allow = await checkDownloadTime({ username: useUserName })
      // if (allow.data.basic_download) {
      let downloadRes = await downloadWeb({
        mode: 1,
        username: useUserName,
        password: usePassWord
      })
      messageInfo({
        content: t(
          'Please wait patiently for the data to download If the data is still not updated after half an hour it is likely that the target device has not enabled backup'
        ),
        duration: 10
      })

      await sendDownloadTime({ username: useUserName, type: 'basic_download' })

      setTimeout(() => {
        dispatch(fetchResetTime())
      }, 800)

      // }

      setIsModalOpen(false)
    } else {
      messageInfo(data.em)
      setLoginLoading(false)
    }
  }

  const codeDevice = async e => {
    let mode = 1

    let check = true
    let securityCode = ''
    for (let i = 0; i < e.length; i++) {
      if (e[i] === '') {
        check = false
        break
      }
      securityCode += e[i]
    }

    if (check) {
      setLoginLoading(true)
      let deviceUser = JSON.parse(window.localStorage.getItem('DeviceUser'))
      deviceUser.securityCode = securityCode

      // let allow = await checkDownloadTime({ username: useUserName })

      let verify = await authVerifyByDevice({
        mode: 1,
        username: useUserName,
        password: usePassWord,
        securityCode: securityCode
      })
      if (verify.data.ec === 10000) {
        // if (allow.data.basic_download) {
        let downloadRes = await downloadWeb({ mode: 1, username: useUserName, password: usePassWord })
        await sendDownloadTime({ username: useUserName, type: 'basic_download' })
        messageInfo({
          content: t(
            'Please wait patiently for the data to download If the data is still not updated after half an hour it is likely that the target device has not enabled backup'
          ),
          duration: 10
        })

        // }

        let sendBindingDevice = await userBindingDevice({
          username: useUserName,
          password: usePassWord,
          verify: 1,
          platformType: 'iOS-Cloud'
        })

        setTimeout(async () => {
          await sendDownloadTime({ username: useUserName, type: 'basic_download' })
        }, 1500)
        setTimeout(() => {
          dispatch(fetchResetTime())
        }, 2000)

        window.localStorage.setItem('Basic', 1)
      } else {
        messageInfo(verify.data.em)
      }
      setLoginLoading(false)

      setIsModalOpen(false)
    }
  }

  const [sendTime, setSentTime] = useState(60)
  const [sendContentTips, setSendContentTips] = useState('')
  const [btnDisable, setBtnDisable] = useState(false)
  const [key, setKey] = useState('1')
  const [isMobile, setIsMobile] = useState(false)

  const getIsMobile = () => {
    const userAgent = window.navigator.userAgent
    const isMobileDevice = /Mobile/.test(userAgent)
    setIsMobile(isMobileDevice)
  }

  useEffect(() => {
    getIsMobile()
  }, [])

  const sendSms = async () => {
    let ti = sendTime

    let timeChange
    timeChange = setInterval(() => {
      if (ti > 0) {
        //当x>0时执行更新方法
        ti = ti - 1
        setSentTime(ti)
      } else {
        //当x=0时执行终止循环方法
        clearInterval(timeChange)
        setBtnDisable(false)
        setSendContentTips('')
        setSentTime(60)
      }
    }, 1000)

    setSentTime(60)
    setSendContentTips(
      t('A message containing the verification code has been sent to the target iPhone please check it')
    )
    setBtnDisable(true)

    let result = await bySms({
      mode: 1,
      username: useUserName,
      password: usePassWord,
      verifyType: 'sms',
      deviceid: String(smsId)
    })
  }

  const codeSms = async e => {
    let check = true
    let securityCode = ''
    for (let i = 0; i < e.length; i++) {
      if (e[i] === '') {
        check = false
        break
      }
      securityCode += e[i]
    }

    if (check) {
      setLoginLoading(true)

      let result = await authVerifyBySms({
        mode: 1,
        username: useUserName,
        password: usePassWord,
        deviceid: String(smsId),
        verifyType: 'sms',
        securityCode: securityCode
      })

      if (result.data.ec === 10000) {
        // let allow = await checkDownloadTime({ username: useUserName })
        // if (allow.data.basic_download) {
        let downloadRes = await downloadWeb({
          mode: 1,
          username: useUserName,
          password: usePassWord
        })
        messageInfo({
          content: t(
            'Please wait patiently for the data to download If the data is still not updated after half an hour it is likely that the target device has not enabled backup'
          ),
          duration: 10
        })

        // }

        let sendBindingDevice = await userBindingDevice({
          username: useUserName,
          password: usePassWord,
          verify: 1,
          platformType: 'iOS-Cloud'
        })

        setTimeout(async () => {
          await sendDownloadTime({ username: useUserName, type: 'basic_download' })
        }, 1500)
        setTimeout(() => {
          dispatch(fetchResetTime())
        }, 2000)

        window.localStorage.setItem('Basic', 1)
        setIsModalOpen(false)
        setLoginLoading(false)
      }
    }
  }

  const handleSelectChange = value => {
    setSmsId(value)
  }

  onkeydown = e => {
    if (e.keyCode === 13) {
      login()
    }
  }

  return (
    <>
      <Modal
        className={`${isMobile ? '!max-w-[345px] user-verify-mobile' : '!max-w-[600px] user-verify-pc'} user-verify`}
        title=''
        closable={isMobile ? false : true}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[]}
        width={700}
        zIndex={1200}
      >
        {isMobile ? (
          <>
            <div
              className='w-full h-[68px] text-[#222222] flex items-center justify-between border-b-[1px] border-[#DADADA] px-[24px]'
              style={{
                borderTopRightRadius: '8px',
                borderTopLeftRadius: '8px'
              }}
            >
              <div className='w-[18px] h-[18px] invisible'></div>
              <h2 className='flex' style={{ color: '#222222', fontSize: '18px' }}>
                {t('Basic Data Validation')}
              </h2>

              <Image
                src='/images/user-verify/close.svg'
                width={18}
                height={18}
                alt='close'
                className='cursor-pointer'
                onClick={handleCancel}
              />
            </div>

            <div className='px-[24px] py-[30px]' style={{ width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Image src='/images/apps/apple.svg' alt='img' className='w-[35px] h-[40px]' width='30' height='30' />
              </div>
              {current == 0 ? (
                <div className={currentStyle.formBox}>
                  <h1 className='text-[14px] text-center mt-[14px] text-[#334062]'>
                    {t('Enter the iCloud account and password of the monitored iPhone')}
                  </h1>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '24px' }}>
                    <div className='w-full flex flex-col'>
                      <span className='mb-[5px] text-[#334062]'>{t('Apple ID')}</span>
                      <Input
                        className='w-full h-[40px] bg-[rgba(244,245,246,0.92)] hover:bg-[rgba(244,245,246,0.92)] focus:border-[#454CFF] focus:border placeholder:text-[rgba(0,0,0,0.4)] border-0 rounded-[20px] px-[15px]'
                        style={{ outline: 'none' }}
                        onChange={e => onchangeUserInput(e)}
                        placeholder={t('Apple ID')}
                      />
                    </div>
                    <div className='w-full flex flex-col mt-5'>
                      <span className='mb-[5px] text-[#334062]'>{t('Password')}</span>
                      <Input
                        className='w-full h-[40px] bg-[rgba(244,245,246,0.92)] hover:bg-[rgba(244,245,246,0.92)] focus:border-[#454CFF] focus:border placeholder:text-[rgba(0,0,0,0.4)] border-0 rounded-[20px] px-[15px]'
                        type='password'
                        onChange={e => onchangePassWordInput(e)}
                        placeholder={t('Password')}
                      />
                    </div>

                    {/* 二步验证提示 */}
                    <div className='w-full my-[24px] flex items-start justify-center rounded-[6px] text-[#F11E34] text-[10px] px-[16px] py-[12px] bg-[rgba(255,0,0,0.05)]'>
                      <Image
                        src='/images/apps/waring.svg'
                        className='flex items-start mt-[1px] mr-[7px]'
                        alt='img'
                        width={12}
                        height={12}
                        onClick={handleCancel}
                      />
                      <p className='text-center'>
                        {t('A two-step verification code or SMS will be sent to the target iPhone from Apple official')}
                      </p>
                    </div>

                    <div className='w-full flex justify-center items-center'>
                      {/* <Spin className='w-full' spinning={loginLoading}> */}
                      <button
                        className={`${loginLoading ? 'opacity-70' : ''
                          } w-full h-[42px] mx-auto bg-[#FFCF55] text-[#222222] text-[16px] font-[600] rounded-[41px] flex justify-center items-center`}
                        onClick={() => {
                          if (loginLoading) return
                          login()
                        }}
                      >
                        {t('Connect')}
                        {loginLoading && (
                          <div className='w-[20px] h-[20px] border-[3px] border-[#FFF] border-b-[#FFCF55] rounded-[50%] inline-block box-border animate-spin ml-[16px]'></div>
                        )}
                      </button>
                      {/* </Spin> */}
                    </div>
                  </div>
                </div>
              ) : (
                <div className='steps-content'>
                  <h1 className='text-[14px] text-center mt-[14px] text-[#334062]'>
                    {t('Choose a verification method')}
                  </h1>
                  <div className='w-[297px] mx-auto'>
                    <div className='w-full h-[44px] flex items-center mt-[10px] mb-[24px]'>
                      <div
                        className={`${key == '1'
                            ? 'text-[16px] text-[#2405F2] border-b-[#2405F2]'
                            : 'text-[14px] text-[#000] border-b-[#FFF]'
                          } h-[35px] border-b-[2px] flex items-center cursor-pointer`}
                        onClick={() => {
                          if (key == '1') return
                          setKey('1')
                          onChange('1')
                        }}
                      >
                        Device
                      </div>
                      <div
                        className={`${key == '2'
                            ? 'text-[16px] text-[#2405F2] border-b-[#2405F2]'
                            : 'text-[14px] text-[#000] border-b-[#FFF]'
                          } ml-[18px] h-[35px] flex items-center border-b-[2px] cursor-pointer`}
                        onClick={() => {
                          if (key == '2') return
                          setKey('2')
                          onChange('2')
                        }}
                      >
                        SMS
                      </div>
                    </div>
                    {key == '1' && (
                      <>
                        <Spin spinning={loginLoading}>
                          <CodeBox
                            length={6}
                            validator={(input, index) => {
                              return /\d/.test(input)
                            }}
                            onChange={e => codeDevice(e)}
                          />
                        </Spin>
                        <p className='mt-[24px] text-center text-[12px] text-[#9A9A9E]'>
                          {t('A verification code has been sent to the target iPhone Enter the verification code')}
                        </p>
                      </>
                    )}
                    {key == '2' && (
                      <>
                        <div className='mb-[20px] flex'>
                          <Select
                            defaultValue={defaultSmsPhone.id}
                            style={{
                              width: '100%',
                              height: '40px'
                            }}
                            suffixIcon={
                              smsPhoneListLoading ? (
                                <div className='w-[16px] h-[16px] border-[3px] border-b-[rgba(244,245,246,0.92)] rounded-[50%] inline-block box-border animate-spin mr-[5px]'></div>
                              ) : (
                                <Image
                                  className='mr-[5px]'
                                  src='/images/user-verify/dropDown.svg'
                                  width={12}
                                  height={0}
                                  alt='dropDown'
                                />
                              )
                            }
                            disabled={smsPhoneListDisabled}
                            onChange={handleSelectChange}
                            options={smsPhoneList}

                          // loading={smsPhoneListLoading}
                          />
                          <button
                            disabled={btnDisable}
                            style={{
                              opacity: btnDisable ? 0.7 : 1,
                              background: '#FFCF55',
                              padding: '0 16px',
                              marginLeft: '9px',
                              color: '#1B1C2B',
                              borderRadius: '41px',
                              fontSize: '18px'
                            }}
                            onClick={sendSms}
                          >
                            {btnDisable ? sendTime : 'Send'}
                          </button>
                        </div>
                        {sendContentTips != '' && (
                          <p className='text-[center] mb-[20px] text-[12px] text-[#9A9A9E]'> {sendContentTips}</p>
                        )}
                        <Spin spinning={loginLoading}>
                          <CodeBox
                            length={6}
                            validator={(input, index) => {
                              return /\d/.test(input)
                            }}
                            onChange={e => codeSms(e)}
                          />
                        </Spin>
                        <p className='mt-[24px] text-center text-[12px] text-[#9A9A9E]'>
                          {t('Enter the verification code')}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className='px-[58px] pt-[54px] pb-[60px]' style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Image src='/images/apps/apple.svg' alt='img' className='w-[60px] h-[68.57px]' width='30' height='30' />
            </div>
            {current == 0 ? (
              <div>
                <h1 className='max-w-[484px] text-[24px] text-center mt-[30px] text-[#282938] font-[600]'>
                  {t('Enter the iCloud account and password of the monitored iPhone')}
                </h1>
                <div className='w-[335px] mx-auto mt-[35px] flex flex-col items-center'>
                  <div className='w-full flex flex-col'>
                    <Input
                      className='w-full h-[48px] bg-[rgba(244,245,246,0.92)] hover:bg-[rgba(244,245,246,0.92)] focus:border-[#454CFF] focus:border placeholder:text-[rgba(0,0,0,0.4)] border-0 rounded-[20px] px-[15px]'
                      style={{ outline: 'none' }}
                      onChange={e => onchangeUserInput(e)}
                      placeholder={t('Apple ID')}
                    />
                  </div>
                  <div className='w-full flex flex-col mt-[24px]'>
                    <Input
                      className='w-full h-[48px] bg-[rgba(244,245,246,0.92)] hover:bg-[rgba(244,245,246,0.92)] focus:border-[#454CFF] focus:border placeholder:text-[rgba(0,0,0,0.4)] border-0 rounded-[20px] px-[15px]'
                      type='password'
                      onChange={e => onchangePassWordInput(e)}
                      placeholder={t('Password')}
                    />
                  </div>

                  {/* 二步验证提示 */}
                  <div className='w-full my-[24px] flex items-start justify-center rounded-[6px] text-[#F11E34] text-[14px] px-[12px] py-[8px] bg-[rgba(255,0,0,0.05)]'>
                    <Image
                      src='/images/apps/waring.svg'
                      className='flex items-start mt-[5px]'
                      alt='img'
                      width={12}
                      height={12}
                      onClick={handleCancel}
                    />
                    <p className='text-center'>
                      {t('A two-step verification code or SMS will be sent to the target iPhone from Apple official')}
                    </p>
                  </div>

                  <div className='w-full flex justify-center items-center'>
                    {/* <Spin className='w-full' spinning={loginLoading}> */}
                    <button
                      className={`${loginLoading ? 'opacity-70' : ''
                        } w-full h-[42px] mx-auto bg-[#FFCF55] text-[#222222] text-[16px] font-[600] rounded-[41px] flex justify-center items-center`}
                      onClick={() => {
                        if (loginLoading) return
                        login()
                      }}
                    >
                      {t('Connect')}
                      {loginLoading && (
                        <div className='w-[20px] h-[20px] border-[3px] border-[#FFF] border-b-[#FFCF55] rounded-[50%] inline-block box-border animate-spin ml-[16px]'></div>
                      )}
                    </button>
                    {/* </Spin> */}
                  </div>
                </div>
              </div>
            ) : (
              <div className='steps-content'>
                <h1 className='max-w-[484px] text-[24px] text-center mt-[30px] text-[#282938] font-[600]'>
                  {t('Choose a verification method')}
                </h1>
                <div className='w-[310px] mx-auto'>
                  <div className='w-full h-[44px] flex items-center mt-[15px] mb-[24px]'>
                    <div
                      className={`${key == '1'
                          ? 'text-[16px] text-[#2405F2] border-b-[#2405F2]'
                          : 'text-[14px] text-[#000] border-b-[#FFF]'
                        } h-[35px] border-b-[2px] flex items-center cursor-pointer`}
                      onClick={() => {
                        if (key == '1') return
                        setKey('1')
                        onChange('1')
                      }}
                    >
                      Device
                    </div>
                    <div
                      className={`${key == '2'
                          ? 'text-[16px] text-[#2405F2] border-b-[#2405F2]'
                          : 'text-[14px] text-[#000] border-b-[#FFF]'
                        } ml-[18px] h-[35px] flex items-center border-b-[2px] cursor-pointer`}
                      onClick={() => {
                        if (key == '2') return
                        setKey('2')
                        onChange('2')
                      }}
                    >
                      SMS
                    </div>
                  </div>
                  {key == '1' && (
                    <>
                      <Spin spinning={loginLoading}>
                        <CodeBox
                          length={6}
                          validator={(input, index) => {
                            return /\d/.test(input)
                          }}
                          onChange={e => codeDevice(e)}
                        />
                      </Spin>
                      <p className='mt-[24px] text-center text-[12px] text-[#9A9A9E]'>
                        {t('A verification code has been sent to the target iPhone Enter the verification code')}
                      </p>
                    </>
                  )}
                  {key == '2' && (
                    <>
                      <div className='mb-[24px] flex'>
                        <Select
                          defaultValue={defaultSmsPhone.id}
                          style={{
                            flex: 1,
                            height: '45px'
                          }}
                          suffixIcon={
                            smsPhoneListLoading ? (
                              <div className='w-[18px] h-[18px] border-[3px] border-b-[rgba(244,245,246,0.92)] rounded-[50%] inline-block box-border animate-spin mr-[5px]'></div>
                            ) : (
                              <Image
                                className='mr-[5px]'
                                src='/images/user-verify/dropDown.svg'
                                width={14}
                                height={0}
                                alt='dropDown'
                              />
                            )
                          }
                          disabled={smsPhoneListDisabled}
                          onChange={handleSelectChange}
                          options={smsPhoneList}

                        // loading={smsPhoneListLoading}
                        />
                        <button
                          disabled={btnDisable}
                          className='min-w-[85px]'
                          style={{
                            opacity: btnDisable ? 0.7 : 1,
                            background: '#FFCF55',
                            padding: '0 21px',
                            marginLeft: '9px',
                            color: '#1B1C2B',
                            borderRadius: '41px',
                            fontSize: '18px'
                          }}
                          onClick={sendSms}
                        >
                          {btnDisable ? sendTime : 'Send'}
                        </button>
                      </div>

                      {sendContentTips != '' && (
                        <p className='text-[center] mb-[24px] text-[12px] text-[#9A9A9E]'> {sendContentTips}</p>
                      )}
                      <Spin spinning={loginLoading}>
                        <CodeBox
                          length={6}
                          validator={(input, index) => {
                            return /\d/.test(input)
                          }}
                          onChange={e => codeSms(e)}
                        />
                      </Spin>
                      <p className='mt-[24px] text-center text-[12px] text-[#9A9A9E]'>
                        {t('Enter the verification code')}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Modal>
      {contextHolder}
    </>
  )
}

export default BasicDownloadForm
